<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:body>
            <div>
              <div class="attachments-operation">
                <b-container fluid v-if="eventId">
                  <form @submit.prevent="handleFileSubmit" enctype="multipart/form-data">
                    <b-row>
                      <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                        <b-row>
                          <b-col sm="5">
                            <b-form-group
                              :label="'Invitation For '+title+' Event *'"
                              label-for="file"
                              :invalid-feedback="formErrors.first('file')"
                            >
                              <b-form-file
                                id="file"
                                @change="onChangeFile"
                              >
                              </b-form-file>
                              <b-form-invalid-feedback
                                :state="(!(formErrors.has('file')))">
                                {{ formErrors.first('file') }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="12">
                            <button type="submit" class="btn btn-primary"> Add Image
                            </button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3" v-for="(image,index) in attachments" :key="index"
                                 class="mb-2">
                            <img :src="image.download_url"
                                 class="img-responsive mb-2 border" width="170"
                                 height="150">
                            <p>
                              <i class="fa fa-trash text-danger"
                                 @click="removeImage(image.id)"></i>
                            </p>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
                <b-container v-else>
                  <h6>Select Event Please</h6>
                </b-container>
              </div><!--/.attachments-operation-->
            </div>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import { getEventId } from "@/util/globalFunction";
import { itemUpdated } from "@/util/notify";

const DEFAULT_FORM_STATE = {
  file: {},
  _method: 'post',
};

export default {
  data() {
    return {
      formFields: { ...DEFAULT_FORM_STATE },
      formErrors: new Error({}),
      file: {},
      title: null,
      attachments: [],
      eventId: getEventId() ? getEventId() : null,
    };
  },
  components: {
    KTCard
  },
  mounted() {
    this.getEventDetails(this.eventId);
  },
  methods: {
    async removeImage(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            url: '/upload/delete',
            method: 'post',
            data: { id: id },
          }).then((response) => {
            this.getEventDetails(getEventId());
          });
        } catch (e) {

        }
      }
    },
    async getEventDetails(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/events/detail/${ id }`,
        });
        this.operationTitle = 'Edit Event';
        const { data } = response.data;
        this.title = data.title;
        if ( data.event ) {
          this.attachments = data.event;
        }
      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    async handleFileSubmit() {
      try {
        const response = await request({
          method: 'post',
          url: `/events/attachments`,
          data: {
            'event_id': this.eventId,
            'file': this.getFile
          }
        });

        this.file = {};
        await this.getEventDetails(this.eventId);

      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }

      // this.formErrors = new Error({})
      // try {
      //     let formData = new FormData()
      //     formData.append('file', this.formFields.file)
      //     formData.append('object_type', 'event')
      //     formData.append('object_id', getEventId())
      //     const response = await request({
      //         url: '/upload',
      //         method: 'post',
      //         data: formData,
      //         headers: {
      //             'Content-Type': 'multipart/form-data'
      //         },
      //     }).then((response) => {
      //         const {data} = response.data
      //         this.attachments.push(data)
      //         this.formFields = {...DEFAULT_FORM_STATE}
      //     });
      // } catch (error) {
      //     if (error.request && error.request.status && error.request.status === 422) {
      //         this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
      //         return false
      //     }
      // }
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
    getFile() {
      return this.file;
    }
  },
};
</script>
